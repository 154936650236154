import classNames from "classnames";
import dayjs from "dayjs";
import { Schedule } from "../../types/Schedule";

interface Props {
	data: Schedule;
}

const ScheduleRow = ({ data }: any) => {
	/*Traffic light functionality
	Green if more than 30 days due
	Orange if due within 30 days
	Red if overdue
	*/
	const themeMap = {
		green: "bg-green",
		yellow: "bg-yellow",
		red: "bg-red",
	};

	// Serial - already exists as field, 'serial' ✅
	// Ref (device id/tag) - already exists, 'reference' ✅
	// Location - already exists, 'location' ✅
	// Next due - already exists, interval.next ✅
	// Traffic light - already exists, interval.next_colour, enum red/yellow/green ✅
	// Status - I'm assuming this is interval.description (?) ✅
	// Calibrated - I suspect this is interval.last ✅
	// Added on fields for label & position ✅

	return (
		<div className="bg-white rounded-md p-3 sm:p-5 text-secondary text-[10px] sm:text-sm font-medium font-display mb-2">
			<div className="flex justify-between items-center">
				<div className="w-[130px] sm:w-[200px] xl:w-[300px]">
					<div>{data.order_line.title}</div>
					<div>Label: {data.label}</div>
					<div>Serial #: {data.serial}</div>
					<div>Reference: {data.reference}</div>
					<div>Location: {data.location}</div>
					<div>Status: {data.interval.description}</div>
					{data.interval.last ? (
						<div>Calibrated: {dayjs.unix(data.interval.last).format("DD/MM/YYYY")}</div>
					) : null}
				</div>
				<div className="flex-1 flex text-right">
					<div className="w-1/2 sm:w-1/3">{data.order_line.id}</div>
					<div className="w-1/3 hidden sm:block">{dayjs.unix(data.created).format("DD/MM/YYYY")}</div>
					<div className="w-1/2 sm:w-1/3 flex justify-end items-center">
						{dayjs.unix(data.interval.next).format("DD/MM/YYYY")}
						<div
							className={classNames(
								"ml-2 sm:ml-5 h-[10px] w-[10px] rounded-full shrink-0 bg-red"
								// `${themeMap[data.interval.next_colour]}`
							)}
						></div>
					</div>
				</div>
				<div className="w-[20px] ml-5">
					{data.file_1 !== null && data.file_1.url && (
						<a href={data.file_1.url} target="_blank" className="w-[18px] cursor-pointer">
							<img
								className="w-full block"
								src={process.env.PUBLIC_URL + "/svgs/download.svg"}
								alt="Process Instruments"
							/>
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default ScheduleRow;
