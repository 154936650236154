import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { IoChevronDownOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectIsEngineer } from "../../redux/reducers/userSlice";
import NavSidebarItem from "./NavSidebarItem";

interface Props {
	revealHeight?: any;
}

function NavSidebar({ revealHeight }: Props) {
	const isEngineer = useSelector(selectIsEngineer);

	const customerNavigation = [
		{
			name: "Orders",
			href: "/orders",
		},
		{
			name: "Tickets",
			href: "/tickets",
			children: [
				{ name: "Open Tickets", href: "/tickets/open" },
				{ name: "Closed Tickets", href: "/tickets/closed" },
				{ name: "New Ticket", href: "/tickets/new" },
			],
		},
		{ name: "Instrument Schedule", href: "/schedule" },
		{ name: "Documents", href: "/documents" },
		{
			name: "Account",
			href: "/account",
			// children: [
			// 	{ name: "Invoices", href: "/account/invoices" },
			// 	{ name: "Account Details", href: "/account/details" },
			// ],
		},
	];

	const engineerNavigation = [
		{ name: "Instrument Schedule", href: "/schedule", children: [] },
		// { name: "Active Visit", href: "/visit/active", children: [] },
		// { name: "Hardware", href: "/", children: [] },
	];

	const [height, setHeight] = useState<number | string>(0);

	let buttonStyle = {
		transform: height === 0 ? "rotate(0deg)" : "rotate(180deg)",
		transition: "transform 150ms ease",
	};

	const navigation = isEngineer ? engineerNavigation : customerNavigation;

	return (
		<AnimateHeight duration={500} height={revealHeight}>
			<nav>
				{navigation.map((item, index) => (
					<div
						key={index}
						className={
							"bg-primary font-display border-b-2 border-primaryDarker px-6" +
							(item.children && item.children.length > 0 ? " pr-0" : "")
						}
					>
						<div className="flex items-center justify-between">
							<Link to={item.href} className="flex-1 text-lg text-white py-4">
								{item.name}
							</Link>
							{item.children && item.children.length > 0 && (
								<button onClick={() => setHeight(height === 0 ? "auto" : 0)} className="py-4 px-6">
									<div style={buttonStyle}>
										<IoChevronDownOutline color="#fff" size={24} />
									</div>
								</button>
							)}
						</div>
						{item.children && item.children.length > 0 && (
							<NavSidebarItem navigation={item.children} height={height} />
						)}
					</div>
				))}
			</nav>
		</AnimateHeight>
	);
}

export default NavSidebar;
